import { makeAutoObservable } from 'mobx';

import { defaultItemsPerPage, itemsPerPageCookieName, tabIds } from 'constants/ui';
import { SelectField } from 'store/fileds/SelectField';
import { cookie } from 'utils/storage';

import type { RootStore } from '../RootStore';
import { TFollowedLoadsTabIds, TTabIds, followedLoadsTabIds } from './UIStore';

type TTabsWithPagination = TFollowedLoadsTabIds | Exclude<TTabIds, 'favorites'>;

type TPages = {
  [key in TTabsWithPagination]: number;
};

export const tabIdsWithPagination = {
  onlyWithOffers: 'onlyWithOffers',
  onlyWithFavorites: 'onlyWithFavorites',
  onlyWithAuctions: 'onlyWithAuctions',
  ...tabIds,
} as const;

const defaultCurrentPage = 1;
const defaultPages = Object.keys(tabIdsWithPagination).reduce((acc, key) => {
  acc[key as TTabsWithPagination] = defaultCurrentPage;
  return acc;
}, {} as TPages);

class Pagination {
  root: RootStore;
  pages: TPages;
  loadsPerPage: SelectField<number>;
  favoritesPerPage: SelectField<number>;
  loadsWithOffersPerPage: SelectField<number>;
  loadsWithAuctionsPerPage: SelectField<number>;

  constructor(root: RootStore) {
    this.root = root;

    this.pages = defaultPages;
    this.loadsPerPage = new SelectField(this.root.options.getItemsPerPageParams);
    this.favoritesPerPage = new SelectField(this.root.options.getItemsPerPageParams);
    this.loadsWithOffersPerPage = new SelectField(this.root.options.getItemsPerPageParams);
    this.loadsWithAuctionsPerPage = new SelectField(this.root.options.getItemsPerPageParams);

    makeAutoObservable(this);
  }

  get activeTab() {
    if (this.root.ui.activeTab === 'favorites') {
      return this.root.ui.followedLoadsTab.data;
    }

    return this.root.ui.activeTab;
  }

  get totalSearchPages() {
    if (!this.root.app.searchResult) return 0;

    return Math.ceil(
      (this.root.app.searchResult?.totalItems - this.root.app.hiddenLoadsCount) / this.loadsPerPage.data,
    );
  }

  get isLastSearchPage() {
    return this.pages.search === this.totalSearchPages;
  }

  get totalFavoritesPages() {
    return Math.ceil(this.root.followedLoads.favorites.count / this.favoritesPerPage.data);
  }

  get totalLoadsWithOffersPages() {
    return Math.ceil(this.root.followedLoads.loadsWithOffers.count / this.loadsWithOffersPerPage.data);
  }

  get totalLoadsWithAuctionsPages() {
    return Math.ceil(this.root.followedLoads.loadsWithAuctions.count / this.loadsWithAuctionsPerPage.data);
  }

  get totalPages() {
    switch (this.activeTab) {
      case followedLoadsTabIds.onlyWithFavorites:
        return this.totalFavoritesPages;
      case followedLoadsTabIds.onlyWithOffers:
        return this.totalLoadsWithOffersPages;
      case followedLoadsTabIds.onlyWithAuctions:
        return this.totalLoadsWithAuctionsPages;
      default:
        return this.totalSearchPages;
    }
  }

  get currentPage() {
    return this.pages[this.activeTab];
  }

  setCurrentPage = (page: number) => {
    this.pages[this.activeTab] = page;
  };

  init = () => {
    let itemsPerPage: string | void;

    if (!this.root.profile.data?.isUser || !this.root.profile.data?.isPaidUser) {
      itemsPerPage = defaultItemsPerPage;
      cookie.set({ name: itemsPerPageCookieName, value: itemsPerPage, days: 365 });
    } else {
      itemsPerPage = cookie.get(itemsPerPageCookieName);
    }

    if (itemsPerPage) {
      this.loadsPerPage.setData('value', Number(itemsPerPage));
    }
  };
}

export { Pagination };
